@font-face {font-family: 'proxima-nova';src: url('./assets/fonts/263383_0_0.eot');src: url('./assets/fonts/263383_0_0.eot?#iefix') format('embedded-opentype'),url('./assets/fonts/263383_0_0.woff') format('woff'),url('./assets/fonts/263383_0_0.ttf') format('truetype');} 
@font-face {font-family: 'ProximaNovaS-Light';src: url('./assets/fonts/263383_1_0.eot');src: url('./assets/fonts/263383_1_0.eot?#iefix') format('embedded-opentype'),url('./assets/fonts/263383_1_0.woff') format('woff'),url('./assets/fonts/263383_1_0.ttf') format('truetype');}
@font-face {font-family: 'ProximaNova-Light';src: url('./assets/fonts/263383_2_0.eot');src: url('./assets/fonts/263383_2_0.eot?#iefix') format('embedded-opentype'),url('./assets/fonts/263383_2_0.woff') format('woff')}
 
body {
  color: #194150;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif !important;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

h1 {
  font-weight: 300;
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: 0;
}

h2 {
  font-weight: 100;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 0;
}

@media only screen and (max-width: 480px) {
  header {
    height: 84px;
  }
  
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 24px;
  }
}

/* Variables */
:root {
  --fontSize-xs: 0.75rem;
  --fontSize-sm: 0.875rem;
  --fontSize-md: 1rem;
  --fontSize-lg: 1.125rem;
  --fontSize-xl: 1.25rem;
  --fontSize-2xl: 1.5rem;

  --icon-fontSize-sm: 1rem;
  --icon-fontSize-md: 1.25rem;
  --icon-fontSize-lg: 1.5rem;

  /* Remember to keep in sync with theme breakpoints */
  --maxWidth-xs: 0;
  --maxWidth-sm: 600px;
  --maxWidth-md: 900px;
  --maxWidth-lg: 1200px;
  --maxWidth-xl: 1440px;
}