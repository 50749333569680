@import '../../../styles/base.module.scss';

.accountForm {
  .buttons {
    button {
      margin: 50px 20px 50px 0;
    }
  }

  .autocompleteWrapper {
    position: absolute;
    width: 100%;
    top: 56px;
    background: #F9FAFC;
    z-index: 100;
  }
}