.cover {
  max-width: 319px;
  max-height: 319px;
}

.coverPlaceholder {
  width: 319px;
  height: 319px;
  background-color: #F3F4F6;
}

.overview {
  .label {
    font-weight: bold;
  }
}